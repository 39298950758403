.missing-data {
	opacity: 0.6;
	text-transform: lowercase;
	font-style: italic;
}

.white-box {
	background-color: rgba(255, 255, 255);
	padding: 0.75em;
	border-radius: 7px;
	border: 1px solid #ddd;

	> h4:first-child {
		background-color: rgba(0, 0, 0, 0.1);
		margin: -0.4em -0.4em 0.5em -0.4em;
		font-size: 1em;
		padding: 0.5em;
	}

	common-button-column,
	common-button-row {
		margin-top: 1em;
	}
}

.grey-box {
	background-color: rgba(0, 0, 0, 0.1);
	padding: 0.75em;
	border-radius: 7px;
	border: 1px solid #ddd;
}