@import "mobile-variables";
@import "mobile-bootstrap-override";
@import "../common/root.scss";
@import "@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-pro/scss/regular.scss";
@import "@fortawesome/fontawesome-pro/scss/solid.scss";
@import "@fortawesome/fontawesome-pro/scss/brands.scss";
@import "@fortawesome/fontawesome-pro/scss/duotone.scss";
@import "@fortawesome/fontawesome-pro/scss/_duotone-icons.scss";
@import "mobile-swoops";
@import "mobile-scrollbars";
@import "mobile-text-classes";
@import "mobile-color-classes";
@import "mobile-fonts";
@import "mobile-headers";
@import "mobile-buttons";
@import "mobile-cards";
@import "mobile-lists";


body {
	background: #eef3f3;
	overflow: hidden;
}